export const API_URL = {
  baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL || 'localhost',

  signIn: '/auth/sign-in',
  signUp: '/auth/sign-up',
  phoneSendVerification: '/auth/phone_number/send_verification',
  phoneCheckVerification: '/auth/phone_number/check_verification',
  emailSendConfirmation: '/auth/confirm_email/send',
  emailConfirmConfirmation: '/auth/confirm_email/confirm',
  sendSmsToChangeForgottenPassword: '/auth/forget_password/phone/send',
  sendUrlToChangeForgottenPassword: '/auth/forget_password/email/send',
  changeForgottenPasswordEmail: '/auth/forget_password/email',
  changeForgottenPasswordPhone: '/auth/forget_password/phone',

  changeEmail: '/auth/email',
  changePhone: '/auth/phone',
  changePassword: '/auth/password',
  checkEmail: '/auth/check/email',
  checkPhone: '/auth/check/phone',
  checkPassword: '/auth/check/password',
  sendConfirmation: '/auth/email/send',

  getMe: '/users/me',
  deleteProfile: '/users',
  updateNotificationTime: '/users/notification',

  getMyTherapists: '/patients/therapist',
  getPatientProfile: '/patients/profile',
  updatePatientInformation: '/patients',
  updateAvatar: '/patients/avatar',
  deleteClientAvatar: '/patients/avatar',
  getClientSessions: '/patients/sessions',
  getClientHistorySessions: '/patients/sessions/history',
  getClientTotalSessions: '/patients/sessions/total',
  getClientActiveSessions: '/patients/sessions/active',
  getTherapistSessions: '/therapists/sessions',
  getTherapistActiveSessions: '/therapists/sessions/active',
  getTherapistHistorySessions: '/therapists/sessions/history',
  getTherapistFreeAvailabilities: (therapistId: string) =>
    `/therapists/${therapistId}/availability`,

  confirmCodeSignUpTherapistByEmail: '/therapist/sign-up-email/confirm',

  getTherapyMethods: '/common/therapy_methods',
  getDiseases: '/common/diseases',
  getGroupDiseases: '/common/diseases_groups',
  getFaq: '/common/faq',
  getClientsFaq: '/common/faq',
  getPublicAssignedUrl: '/get-public-signed-url',

  postClientOnboarding: '/patients/onboarding',
  postClientRecommendationFilter: '/patients/recommendation-filter',

  getSessionNotes: (sessionId: string) => `/sessions/${sessionId}/notes`,
  addSessionNote: (sessionId: string) => `/sessions/${sessionId}/notes`,
  addSessionOutcome: (sessionId: string) => `sessions/${sessionId}/outcome`,

  deleteSessionsWithTherapist: (therapistId: string) =>
    `/sessions/therapist/${therapistId}`,

  createAvailabilities: '/therapists/availability',
  updateAvailabilities: (availabilityId: string) =>
    `/therapists/availability/${availabilityId}`,
  deleteAvailabilities: (availabilityId: string) =>
    `/therapists/availability/${availabilityId}`,
  getSchedule: '/therapists/schedule',

  getAllTherapists: '/therapists/all',

  postCreateProfile: '/therapists',
  getTherapistProfile: '/therapists/profile',
  getTherapistProfileUpdate: '/therapist-update',
  deleteTherapistProfileUpdate: (updateId: string) =>
    `/therapist-update/${updateId}`,
  postTherapistProfile: '/therapist-update/',
  getClients: '/therapists/patients',
  getClientById: (patientId: string) => `/patients/${patientId}`,
  deleteVideo: '/therapists/video',
  deleteTherapistAvatar: '/therapists/avatar',

  getPatientNotes: (patientId: string) =>
    `/therapists/patient/${patientId}/notes`,
  addPatientNote: (patientId: string) =>
    `/therapists/patient/${patientId}/notes`,
  editPatientNote: (patientId: string, noteId: string) =>
    `/therapists/patient/${patientId}/notes/${noteId}`,
  deletePatientNote: (patientId: string, noteId: string) =>
    `/therapists/patient/${patientId}/notes/${noteId}`,

  archivePatient: (patientId: string) =>
    `/therapists/patients/${patientId}/archive`,
  unarchivePatient: (patientId: string) =>
    `/therapists/patients/${patientId}/unarchive`,

  rejectPatient: (patientId: string) => `/therapists/patients/${patientId}`,

  getTherapistsWithFilter: '/therapists/search/with_filter',
  getTherapistsRecommended: '/therapists/search/recommended',

  bookSession: '/session-payment/',
  bookSessionV2: '/session-booking/',
  bookSessionByTherapist: '/session-payment/book_by_therapist',
  getUpcomingSessionPaymentInfo: (availabilityId: string) =>
    `/session-payment/upcoming/${availabilityId}`,

  getPayments: '/payments',
  getBankCardData: '/payment-gateway/payment-method',
  deleteBankCard: '/payment-gateway/payment-method/detach',
  getCheckoutUrl: '/payment-gateway/payment-method/attach',

  joinSession: (sessionId: string) => `sessions/${sessionId}/join`,
  getTestSessionToken: 'sessions/get-test-session-token',
  cancelSession: (sessionId: string) => `session-payment/${sessionId}/cancel`,
  cancelSessionV2: 'session-booking/cancel',

  timeshiftSession: '/session-booking/reschedule',

  getCompanyStatistic: 'companies/statistics',
  getPromoCodes: 'companies/promocodes',
  requestPromoCodes: 'companies/promocodes-request',
  sendPromoCodes: 'companies/promocodes/send',

  checkPromocode: 'promocodes/check',
  checkPromocodeByTherapist: 'promocodes/check-by-therapist',

  getNotifications: '/notifications',
  markAllNotificationsViewed: '/notifications',
  clearNotifications: '/notifications',
  authPusher: '/pusher/auth',

  getPaymentStatistic: '/payments/statistics',
  getCompanyStatisticsXls: '/companies/statistics/xls',

  sendRequestSession: (therapistId: string) =>
    `/therapists/${therapistId}/request_session`,

  medsiValidateToken: '/medsi/auth/validate-token',
  medsiSignUp: '/medsi/auth/sign-up',
  medsiSignIn: '/medsi/auth/sign-in',
  MedsiRecover: '/medsi/auth/recover',

  signUpPhone: '/auth/sign-up/phone',
  signUpEmail: '/auth/sign-up/email',
  signInPhone: '/auth/sign-in/phone',
  signInPhoneSendVerification: '/auth/sign-in/phone/send_verification',

  updateEmailWithoutConfirmation: 'auth/email/with_no_confirmation',

  webhookAmoMirUserSentForm: '/webhook-amo/mir-user-sent-form',

  pixelWrite: '/logger/pixel',
};
