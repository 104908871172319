import { RoutesPath } from '@app/routers';
import { makeFriendlyDayObj } from '@app/utils/makeFriendlyDayObj';
import { Therapist } from '@app/pageComponents/TherapistDetailsPage/types';
import { ReactNode, SVGProps } from 'react';
import {
  AvailabilityRecurringResponseDtoTypeEnum,
  TherapistSearchRequestDtoOrderEnum,
} from '@app/api';
import { AdditionalFilter } from '@app/pageComponents/AllTherapistsPage/constants';

export type UserRoles = 'client' | 'therapist';

export interface SelectOption<T = string> {
  value: T;
  label: string;
}

export interface TabData {
  text: string;
  value: string;
}

export enum InputState {
  success = 'success',
  error = 'error',
}

export interface SideNavigationItem {
  to: RoutesPath;
  title: string;
  exact?: boolean;
}

export interface Consultation {
  id: string;
  availabilityRecurringId?: string;
  start: DayObj;
  type: ScheduleConsultationTypes;
  isFree: boolean;
  isRecurring: boolean;
  recurringType?: AvailabilityRecurringResponseDtoTypeEnum;
  patient?: {
    id: string;
    avatarUrl?: string;
    firstname?: string;
  };
}

export interface DayObj extends ReturnType<typeof makeFriendlyDayObj> {
  consultations?: Consultation[];
}

export type ParentVideoElement<T> = T & {
  webkitRequestFullscreen: () => void;
  msRequestFullscreen: () => void;
  webkitExitFullscreen: () => void;
  msExitFullscreen: () => void;
};

export interface SpecialistCardItem {
  imgSrc?: string;
  userName: string;
  experience: string;
  specialization: string;
  description: string;
  directions: string;
  availableTime: string;
  price: string;
  id: string;
  consultationFormat?: string;
  education?: {
    title: string;
    text: string;
  }[];
  videoSrc?: string;
  isWorksWithLGBT?: boolean;
  tagList?: {
    title?: string;
    tags?: string[];
  }[];
  consultations?: { date: string }[];
}

export enum FilterInputType {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Switch = 'switch',
  Slider = 'slider',
  Range = 'range',
  RangeSecond = 'rangeSecond',
}

export type IFilter = {
  icon?: ReactNode;
  tooltip?: ReactNode;
  checked?: string[];
  canExpand?: boolean;
} & AdditionalFilter;

export interface Availability {
  id: string;
  therapistId: string;
  duration: number; // в минутах(мб потом поменяем на часы)
  startDateUtc: Date;
  endDateUtc: Date;
  isFree: boolean;
}

export interface Session {
  id: string;
  therapistId: string;
  availabilityId: string;
  sessionRequestId: string;
  status: 'awaiting' | 'live' | 'complete' | 'canceled';
  type: ScheduleConsultationTypes;
  cancelerId: string;
  cancelReason: string;
  isPaid: boolean;
  patients: {
    id: string;
    userId: string;
    firstname: string;
    lastname: string;
    dateOfBirth: Date;
    role: 'patient' | 'therapist';
    avatarUrl: string;
  }[];
}

export interface Schedule {
  id: string;
  userId: string;
  availabilities: Availability[];
  sessions: Session[];
  sessionRequests: {
    id: string;
    therapistId: string;
    availabilityId: string;
    status: 'pending' | 'accepted' | 'rejected' | 'canceled' | 'expired';
    type: ScheduleConsultationTypes;
    duration: number; // в минутах(мб потом поменяем на часы)
    patient: {
      id: string;
      userId: string;
      firstname: string;
      lastname: string;
      dateOfBirth: Date;
      role: 'patient' | 'therapist';
      avatarUrl: string;
    };
  }[];
}

export interface IconComponent extends SVGProps<SVGSVGElement> {
  color?: string;
}

export interface SessionPayment {
  day: Date;
  remind: string;
  time: string;
  therapist: Therapist;
}

export interface IMyTherapist {
  id: string;
  firstname: string;
  dateOfBirth: string;
  avatarKey: string;
  sessionCount: number;
  firstSessionDate: string;
  lastSessionDate: string;
}
export interface IMyPayment {
  firstname: string;
  avatarKey: string;
  sessionDate: string;
  dateOfPayment: string;
  paymentAmount: string;
}

export enum PaymentStatus {
  inProcess = 'В процессе',
  success = 'Успешно',
  refund = 'Возврат',
}

export interface IPaymentReceived {
  firstname: string;
  avatarKey: string;
  sessionDate: string;
  dateOfPayment: string;
  status: PaymentStatus;
}

export enum ConsultationLanguage {
  english = 'english',
  russian = 'russian',
  kazakh = 'kazakh',
  german = 'german',
  tatar = 'tatar',
  armenian = 'armenian',
  french = 'french',
  spanish = 'spanish',
  italian = 'italian',
  chinese = 'chinese',
  arab = 'arab',
  azerbaijani = 'azerbaijani',
  georgian = 'georgian',
  uzbek = 'uzbek',
}

export enum Religions {
  christianity = 'christianity',
  islamism = 'islamism',
  judaism = 'judaism',
  buddhism = 'buddhism',
}

export enum TypeAdvice {
  consultation = 'Консультация',
  mySupervision = 'Моя супервизия',
  supervision = 'Супервизия',
  cancelled = 'Отменено',
}

export enum HttpMethods {
  post = 'POST',
  get = 'GET',
  put = 'PUT',
  patch = 'PATCH',
  delete = 'DELETE',
  request = 'REQUEST',
}

export enum ScheduleConsultationTypes {
  session = 'session',
  supervision = 'supervision',
  mySupervision = 'mySupervision',
  empty = 'empty',
}

export enum SignUpStepsLabels {
  main = 'main',
  checkEmail = 'checkEmail',
  checkPhone = 'checkPhone',
  createPassword = 'createPassword',
  enterPhone = 'enterPhone',
  enterEmail = 'enterEmail',
}

export enum TherapistStatus {
  notExists = 'not-exists',
  notVerified = 'not-verified',
  verified = 'verified',
}

export enum PatientStatus {
  notVerified = 'not-verified',
  verified = 'verified',
}

export type ClientsOrderTerms =
  | 'firstName'
  | 'totalSessions'
  | 'firstSessionDate'
  | 'lastSessionDate';

export type PaymentOrderTerms = 'value' | 'dealStartDate' | 'createdAt';

export enum BreakpointsEnum {
  lg = 1440,
  lgm = 1200,
  md = 1024,
  ms = 820,
  sm = 768,
  s = 576,
  xl = 385,
  xs = 320,

  tablet = 1100,
  mb = 500,
}

export type BreakpointsType = keyof typeof BreakpointsEnum;

export type PaginationParams = {
  limit?: number;
  page: number;
  order?: TherapistSearchRequestDtoOrderEnum;
  orderTerms?: string;
};

export enum AvailabilityChangeModes {
  ALL = 'all',
  ONLY_THIS = 'only_this',
  THIS_AND_NEXT = 'this_and_next',
}

export interface TherapistData {
  id: string;
  firstname: string;
  avatarKey?: string | null;
}

export enum UserGenderLabels {
  FEMALE = 'Женщина',
  MALE = 'Мужчина',
  TRANSGENDER = 'Трансгендер',
  UNKNOWN = 'Неопределенный',
  ANY = 'Не важно',
}

export interface NotificationFriendlyData {
  title: string | JSX.Element;
  description: string | JSX.Element;
  date: string;
}

export interface CustomError {
  status: number;
  data: {
    statusCode: number;
    timestamp: string;
    message: string;
    error: string;
    data: Record<string, unknown>;
    path: string;
  };
}

export interface ValidationError {
  status: number;
  data: {
    statusCode: number;
    error: string;
    message: 'Ошибка валидации';
    data: {
      error: string;
      errors: Array<{ property: string; constraints: Record<string, string> }>;
      message: string;
      statusCode: number;
    };
    path: string;
  };
}

export interface SendingFrequencyError {
  status: number;
  data: {
    statusCode: number;
    timestamp: string;
    message: string;
    error: string;
    data: {
      secondsBeforeNextAttempt: number;
    };
    path: string;
  };
}

export interface BaseSortingOption<T extends string = string> {
  value: TherapistSearchRequestDtoOrderEnum;
  label: T;
}

export interface Sorting<S extends string = string, T = BaseSortingOption<S>> {
  title: string;
  name: string;
  options: Array<T>;
}

export type SortingReturn<T extends string = string> = Record<
  T,
  TherapistSearchRequestDtoOrderEnum
>;

export interface ParsedToken {
  _id: string;
  email: string;
  aud: string;
  sign: string;
  iat: number;
  exp: number;
  sub: string;
}

export enum JWTAudiences {
  AUTH = 'auth',
  PASSWORD_CHANGE = 'password-change',
  DOWNLOAD = 'download',
  EMAIL_CONFIRM = 'email-confirm',
  EMAIL_UPDATE = 'email-update',
  RESTORE_PASSWORD = 'restore-password',
  CHANGE_UNCONFIRMED_EMAIL = 'change-unconfirmed-email',
  B2B = 'b2b',
  ADMIN = 'admin',
}

export enum LayoutEnum {
  Main = 'main',
  Therapist = 'therapist',
  Client = 'client',
  Auth = 'auth',
  ClientProfile = 'clientProfile',
  TherapistProfile = 'therapistProfile',
  Business = 'business',
  Back = 'back',
  BackV2 = 'backV2',
  Medsi = 'medsi',
}

export enum VercelEnv {
  production = 'production',
  preview = 'preview',
  development = 'development',
}

export type Maybe<T> = T | null;

export type Empty<T> = T | undefined;

export type EmptyMaybe<T> = T | null | undefined;

export type CommonComponentTypes = {
  className?: string;
};

export const validationErrorTypeGuard = (
  error: any,
): error is ValidationError => {
  return error.data?.message === 'Ошибка валидации';
};
